import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'
import Benifits from '../components/Benifits'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function SecureDataManagement() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Secure Data Manageement and Processing Technology"
        description="All businesses are in a constant threat from hackers, malware, and virus these days. Secure your business data with leading-edge data security solutions from WYN Technologies."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Secure Data Management</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Secure Data Management</GroceryTitle>
              <p>Data security management is without a doubt the most important technology for businesses hoping to operate in today’s digitized society. Without taking extensive steps to protect your sensitive company or customer data, you stand the risk of extreme financial and legal liabilities. The amount of valuable data collected by companies in the technological age is mind-boggling, and all of it is susceptible to breaches by hackers, malware, and virus. It is crucial to maintain as tight of a grip on your data security as possible.</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <Benifits />
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle>
              <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
              <MarkdownContent>
                  <h2>Why is Data Security Important?</h2>
                  <p>This should be obvious! The data your business collects, stores, and exchanges can be a precious asset. And thus, safeguarding it from unauthorized exposure or use is paramount to the livelihood of your company. Beyond ensuring that your business is secure, there are industry and federal regulations surrounding data security management with which you will need to comply.</p>
                 
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Best Data Security Technologies</h2>
                <p>Federal regulations have come into effect seeking to strengthen the data security protections for customers, individuals, and companies in the United States. To maintain industry-standard cybersecurity measures, businesses must prepare for increasingly restrictive protocols that will hold companies responsible for the data they collect.</p>
                <ul>
                  <li>
                    <strong>Data Classification</strong>
                    <p>Classifying data security types is the fundamental starting line when prioritizing just what kind of data your company will need to protect. There are numerous tools to assist user-supported and automated classification technologies, and a well-thought-out classification system will streamline the process of deciphering essential data. Classifying your data is essential to ensuring risk management and regulatory compliance.</p>
                  </li>
                  <li>
                    <strong>Data Access Policies</strong>
                    <p>Determining just who, when, where, and why individuals should access sensitive data is just as crucial as data classification. Authorizing only specific team members to handle secure data can significantly prevent data breaches. You wouldn’t hand over the keys to your business to a total stranger, would you? Then why would you when it comes to you and your customer’s data?</p>
                  </li>
                  <li>
                    <strong>Cloud Data Protection</strong>
                    <p>One of the best ways to ensure data management and security is to utilize cloud-based data protection systems. Encrypting sensitive data and storing it outside your business’ network will make it harder for hackers to breach, fostering an overall safer environment for your data. With cloud-based technology, you will always want to use trustworthy data management systems from reliable companies! Additionally, storing your sensitive data with companies and servers located in the EU can only compound the security, as European regulations are far more stringent than those found in the United States.</p>
                  </li>
                  <li>
                    <strong>Two-Factor Authentification</strong>
                    <p>We’re all exposed to two-factor authentification tools in our daily lives. Two-Factor Authentification (2FA) is a security framework that creates an additional security step beyond a simple password, keeping hackers from breaching an account even if they have your login information. 2FA will generate a unique code sent via email or SMS or require a fingerprint scan in addition to standard login information.</p>
                  </li>
                  <li>
                    <strong>Tokenization</strong>
                    <p>Tokenization is the process of converting any piece of sensitive data into a random string of numbers or characters that are utterly meaningless if found during a breach of your company’s security system. Unlike encryption, there is no mathematical correlation between any token and its respective data, requiring a hacker to access a token map to decipher your data.</p>
                    </li>
                </ul>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Steps to Managing Secure Data</h2>
                <p>Data is the fundamental object driving our global economy in this technological age. It keeps organizations secure, reliable and profitable, and thus, sensitive data should be handled with enormous respect. This means keeping data safe and available to meet the demands of your business! Here are some of the best steps to achieving secure data management:</p>
                <ul>
                  <li>
                    <strong>Choosing the correct framework</strong>
                    <p>Vital to a comprehensive data security plan is taking steps to define the overall architecture you’ll need for maintaining security at each level of your business- all the way from your database to the network at large. You will want to develop a model to provide a solid security framework with integrated and upgradeable technologies that keeps information retained to a single, central repository with automated access control measures.</p>
                  </li>
                  <li>
                    <strong>Consolidating Systems and Organizing Data in a Holistic Manner</strong>
                    <p>Developing a holistic approach to consolidate previously quarantined systems will segregate data at various security levels. Agencies that take these measures often avoid the headache of maintaining separate systems and transferring information between them, leading to data duplication issues, and wasted storage.</p>
                  </li>
                  <li>
                    <strong>Combining Compute and Storage</strong>
                    <p>Combining storage, networking, and computing within a single system greatly cuts complexities for data center managers while simultaneously increasing efficiency and scalability.</p>
                  </li>
                  <li>
                    <strong>Identifying Common Security Weaknesses</strong>
                    <p>Maintaining a solid understanding of your data and systems can help you navigate potential security issues for your data. You will need to take special consideration of the biggest threats your data will face and develop a game plan. For every potential security threat, you will want at least two solutions, like authentification or encryption followed by automatic data rejection and port closure measures.</p>
                  </li>
                  <li>
                    <strong>Recognizing Potential and Natural Threats</strong>
                    <p>Not all security threats are created equal, and not all of them are digital! Planning only for digital attacks will create blind spots in your overall data control. Physical attacks like break-ins, hardware failures, and natural disasters are just as much of a threat to your data security! You will want to maintain physical security measures such as locking server rooms, developing secure entrances, and maintaining backup procedures for sites prone to volatile weather events.</p>
                    </li>
                </ul>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Data Security Management at WYN Technologies</h2>
                  <p>WYN Technologies is here to provide data security solutions for you, your home, and your business! Data management is essential in this digital age, and our trained experts can help you learn more about industry-standard data security techniques so that you can preserve your company’s most sensitive asset- Data! </p>
                 
                <p>Utilizing state-of-the-art methods like end-to-end encryption and two-factor authentification, we can provide the most up-to-date security measures that keep your business safe from hackers, malware, and viruses. Give our specialists a call today at <a href="tel:3368990555">(336) 899-0555</a> to learn more!</p>
                </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default SecureDataManagement
